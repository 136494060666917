.about-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    max-width: 400px;
    height: 50vh;
    overflow-y: auto;
    /* border: 1px solid rgb(35, 34, 34); */
    border-radius: 10px;
    position: absolute;
    top: 18%;
    padding: 30px;
    
}


.about {
    position: absolute;
    top: 5%;
    color: brown;
}

.about-text {
    position: absolute;
    top: 15%;
    font-size: .8rem;
}


.about-terms {
    position: absolute;
    bottom: 18%;

}

.about-privacy {
    position: absolute;
    bottom: 24%;
}

.terms-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 70%;
    max-width: 500px;
    position: absolute;
    top: 25%;
    font-size: .9rem;
    padding-bottom: 50px;
}


@media only screen and (max-width: 540px) {
    .about-container {
        max-width: 300px;
    }

   .about-terms {
    bottom: 10%;
   }

   .about-privacy {
    bottom: 16%;
   }
    
}

@media only screen and (max-width: 390px) {
    .about-container {
        max-width: 250px;
    }

   .about-terms {
    bottom: 14%;
   }

   .about-privacy {
    bottom: 20%;
   }

   .about-text {
    font-size: .9rem;
    
   }
    
}

@media only screen and (max-width: 430px) {
    .terms-text {
      top: 20%;
    }
    
}
.settingicon-profile {
    position: absolute;
    width: 4%;
    top: 2%;
    right: 2%;
    cursor: pointer;
}

.messageicon-profile {
    position: absolute;
    width: 4%;
    top: 2%;
    right: 6%;
    cursor: pointer;
}

.uploadicon-profile {
    position: absolute;
    width: 4%;
    top: 2.4%;
    left: 2%;
    cursor: pointer;
}

.share-icon {
    position: absolute;
    width: 3%;
    top: 2.5%;
    left: 6%;
    cursor: pointer;
}

.share-icon-op {
    position: absolute;
    width: 3%;
    top: 2.5%;
    left: 1%;
    cursor: pointer;
}

.profilephoto {
    position: absolute;
    width: 320px;
    height: 320px;
    background-color: rgb(13, 13, 13);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 17%;
    
}

.profilephoto-display {
    width: 320px;
    height: 320px;
    border-radius: 10px;
    object-fit: cover;
}

.profile-name {
    position: absolute;
    top: 8.5%;
    font-size: 2rem;
}

.profile-category {
    position: absolute;
    bottom: 21%;
    /* left: 40%; */
    color: brown;
    font-size: .9rem;
    letter-spacing: -1.1px;
}

.profile-bio {
    width: 300px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    top: 62%;
    position: absolute;
    font-size: 1rem;
}

.editprofile-button {
    position: absolute;
    bottom: 17%;
}

.email-popup {
    width: 70vh;
    max-width: 220px;
    height: 30px;
    background-color: rgb(13, 13, 13);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 7%;
    right: 3%;
    font-size: .8rem;
}

.share-popup {
    width: 70vh;
    max-width: 220px;
    height: 45px;
    background-color: rgb(13, 13, 13);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    flex-direction: column;
    top: 7%;
    left: 3%;
    font-size: .6rem;
    cursor: pointer;
}

.sharelink {
    position: absolute;
    top: 0;
}

.copylink {
    position: absolute;
    bottom: 0;
    color: brown;

}

.copy-success-message {
    position: absolute;
    top: 6%;
    left: 10%;
    font-size: .6rem;
}


.email-button {
    background-color: rgb(13, 13, 13);
    border: none;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 1%;
    right: 1%;
}


@media only screen and (max-width: 540px)  {

    .uploadicon-profile {
        width: 8%;
        top: 1%;
    }

    .share-icon {
        width: 7%;
        top: 1%;
        left: 10%;
    }

    .share-icon-op {
        width: 7%;
        top: 1%;
        left: 10%;
        left: 2%;
    }

    .settingicon-profile {
        width: 8%;
        top: 1%;
    }

    .messageicon-profile {
        width: 8%;
        top: 1%;
        right: 10%;
    }

    .profilephoto {
        top: 21%;
    }

    .profile-name {
        font-size: 1.4rem;
        top: 15%;
    }

    /* .profile-category {
        left: 25%;
        top: 49%;
    } */
    
}


@media only screen and (max-width: 430px)  {

    .uploadicon-profile {
        width: 10%;
        top: 1%;
    }

    .share-icon {
        width: 8%;
        top: 1%;
        left: 11%;
    }

    .share-icon-op {
        width: 8%;
        
    }

    .settingicon-profile {
        width: 10%;
        top: 1%;
    }

    .messageicon-profile {
        width: 10%;
        top: 1%;
        right: 12%;
    }

    /* .profile-category {
        left: 25%;
        top: 48%;
    } */

    .profilephoto {
        width: 270px;
        height: 270px;
        top: 22%;
    }

    .profilephoto-display {
        width: 270px;
        height: 270px;
        
    }

    .profile-name {
        top: 16%;
        font-size: 1.5rem;
    }
    
}

@media only screen and (max-width: 390px) {
    /* .profile-category {
        top: 50%;
    } */

    .profilephoto {
        width: 250px;
        height: 250px;
        top: 24%;
    }

    .profilephoto-display {
        width: 250px;
        height: 250px;
        
    }

    .profile-name {
        font-size: 1.5rem;
        top: 17%;
    }
    
}

/* @media only screen and (max-width: 1280px) {
    .profile-category {
        top: 56%;
        left: 40%;
    }

    .editprofile-button {
        bottom: 15%;
    }

    .profile-bio {
        top: 65%;
    }
    
}

@media only screen and (max-width: 1194px) {
    .profile-category {
        top: 55%;
        left: 38%;
    }

   
    
}


@media only screen and (max-width: 1024px) {
    .profile-category {
        top: 57%;
        left: 38%;
    }

   
    
}

@media only screen and (width: 1366px) and (height: 1024px) {
    .profilephoto {
        width: 350px;
        height: 350px;
        top: 20%;
    }

    .profilephoto-display {
        width: 350px;
        height: 350px;
        
    }

    .profile-name {
        top: 13%;
    }

    .profile-category {
        top: 52%;
    }

    .profile-bio {
        top: 65%;
    }

    .editprofile-button {
        bottom:  12%;
    }
}

@media only screen and (width: 1024px) and (height: 1366px) {
    .profilephoto {
        width: 400px;
        height: 400px;
        top: 20%;
    }

    .profilephoto-display {
        width: 400px;
        height: 400px;
        
    }

    .profile-name {
        top: 13%;
    }

    .profile-category {
        top: 49%;
        left: 34%;
        font-size: 1.3rem;
    }

    .profile-bio {
        top: 65%;
        font-size: 1.5rem;
    }

    .editprofile-button {
        bottom:  12%;
        max-width: 220px;
        height: 60px;
        font-size: 1.3rem;
    }

    .uploadicon-profile {
        width: 10%;
        top: 1%;
    }

    .settingicon-profile {
        width: 10%;
        top: 1%;
    }

    .messageicon-profile {
        width: 10%;
        top: 1%;
        right: 12%;
    }

}

@media only screen and (max-width: 834px)  {

    .uploadicon-profile {
        width: 8%;
    }

    .settingicon-profile {
        width: 8%;
    }

    .messageicon-profile {
        width: 8%;
        right: 10%;
    }






    .profilephoto {
        width: 370px;
        height: 370px;
        top: 20%;
    }

    .profilephoto-display {
        width: 370px;
        height: 370px;
        
    }

    .profile-name {
        top: 13%;
    }

    .profile-category {
        top: 50%;
        left: 30%;
        font-size: 1.3rem;
    }

    .profile-bio {
        top: 65%;
        font-size: 1.3rem;
    }

    .editprofile-button {
        bottom:  12%;
        max-width: 250px;
        height: 60px;
        font-size: 1.3rem;
    }
}

@media only screen and (max-width: 768px) {
    .profile-category {
        top: 55%;
    }
    
}

 */

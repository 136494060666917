.setting-container {
    width: 70%;
    max-width: 600px;
    height: 70vh;
    position: absolute;
    border: 1px solid rgb(101, 101, 101);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    top: 10%;
}

.setting-logo {
    max-width: 350px;
    width: 50%;
    top: 2%;
    position: absolute;
}

.setting-text {
    position: absolute;
    color: brown;
    top: 25%;
    font-size: 1.2rem;
}

.settings-option {
    position: absolute;
    cursor: pointer;
    font-size: 1.2rem;
}

.settings-option:hover {
    color: brown;
}

#about {
    top: 44%;
}

#privacy {
    top: 50%;
}

#terms {
    top: 56%;
}

#logout {
    top: 62%;
}

#delete-account {
    top: 68%;
}


@media only screen and (max-width: 540px) {
    .setting-logo {
        top: 8%;
        width: 65%;
    }
    
}

@media only screen and (max-width: 390px) {
    .settings-option {
        font-size: 1rem;
    }
    
}

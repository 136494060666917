.navbar-container {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    border-top: 2px solid rgb(65, 63, 63);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: black;
    z-index: 1000;
}

.explore-profile {
    font-size: 2.6rem;
    position: absolute;
    cursor: pointer;
}

.explore-profile:hover {
    color: brown;
}


.profileicon {
    width: 5%;
    left: 35%;
    position: absolute;
    cursor: pointer;
}

.notification-icon {
    width: 5%;
    right: 35%;
    position: absolute;
    cursor: pointer;
}

@media only screen and (max-width: 540px) {
    .navbar-container {
        height: 60px;
    }

    .explore-profile {
        font-size: 2rem;
    }

    .profileicon {
        width: 8%;
        left: 25%;
    }

    .notification-icon {
        width: 8%;
        right: 25%;
    }
    
    

    
}

@media only screen and (max-width: 430px) {
    .navbar-container {
        height: 50px;
    }

    .explore-profile {
        font-size: 1.7rem;
    }

    .profileicon {
        width: 9%;
        left: 24%;
    }

    .notification-icon {
        width: 9%;
        right: 24%;
    }
    
    

    
}

@media only screen and (max-width: 834px) {
    /* .navbar-container {
        height: 50px;
    }

    .explore-profile {
        font-size: 1.7rem;
    } */

    .profileicon {
        width: 9%;
        left: 24%;
    }

    .notification-icon {
        width: 9%;
        right: 24%;
    }
    
    

    
}







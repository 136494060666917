.homemodal-content {
    background: rgb(15, 15, 15);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    
    position: absolute;
    max-width: 750px;
    width: 70%;
    height: 80vh;
}

.hm-photo {
    width: 350px;
    height: 350px;
    border-radius: 10px;
    object-fit: cover;
    position: absolute;
    top: 18%;
    cursor: pointer;
}

.hm-name {
    position: absolute;
    top: 7%;
    cursor: pointer;
}

.hm-description {
    position: absolute;
    top: 70%;
}

.hm-time {
    position: absolute;
    bottom: 2%;
    left: 3%;
    font-size: 12px;
}

.hm-username {
    position: absolute;
    top: 0%;
    left: 3%;
    color: brown;
    cursor: pointer;
}

.home-like {
    position: absolute;
    bottom: 2%;
}

.share {
    position: absolute;
    bottom: 2%;
    right: 2%;
    font-size: 1.0rem;
    cursor: pointer;
}

.share:hover {
    color: brown;
}

@media only screen and (max-width: 540px) {
    .homemodal-content {
        width: 75%;
        height: 70vh;
    }

    .hm-photo {
        width: 275px;
        height: 275px;
        top: 23%;
    }

    .hm-name {
        top: 13%;
        font-size: 1.7rem;
    }
    
    .hm-description {
        top: 68%;
    }
}

@media only screen and (max-width: 430px) {
    .homemodal-content {
        width: 75%;
    }

    .hm-photo {
        width: 225px;
        height: 225px;
        top: 25%;
    }

    .hm-name {
        top: 16%;
        font-size: 1.5rem;
    }
    
    .hm-description {
        top: 65%;
    }
}

@media only screen and (max-width: 430px) {
    .homemodal-content {
        width: 80%;
    }

   
}



.ep-container {
    width: 80%;
    max-width: 550px;
    height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: 1px solid rgb(101, 101, 101);
    position: absolute;
}

.logo {
    position: absolute;
    top: 4%;
    
    max-width: 300px;
    width: 50%;
}

.edit-profile {
    position: absolute;
    top: 17%;
    color: brown;
}

.ep-profilephoto {
    position: absolute;
    width: 200px;
    height: 200px;
    background-color: black;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 26%;
    /* left: 10%; */
    cursor: pointer;
    border: 1px solid rgb(54, 54, 54);
}

.setting-icon {
    width: 50px;
    position: absolute;
    top: 10%;
    right: 8%;
    cursor: pointer;
}

.ep-email {
    position: absolute;
    left: 20%;
    top: 72%;
    width: 150px;
}

.ep-category {
    position: absolute;
    right: 21%;
    top: 72%;
    width: 150px;
}

.ep-name {
    position: absolute;
    left: 20%;
    top: 81%;
    width: 150px;
}

.ep-username {
    position: absolute;
    right: 20%;
    top: 81%;
    width: 150px;
}

.ep-save {
    position: absolute;
    bottom: 4%;
}

.ep-bio {
    position: absolute;
    width: 250px;
    height: 50px;
    background-color: black;
    color: aliceblue;
    border-radius: 5px;
    border: 1px solid rgb(54, 54, 54);
    top: 59%;
}

.ep-photopreview {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    object-fit: cover;
}

.ep-error-message {
    color: darkorange;
    position: absolute;
    top: 51%;
}

@media only screen and (max-width: 540px) {
    .ep-email {
        left: 10%;
    }

    .ep-category {
        right: 10%;
    }

    .ep-name {
        left: 10%;
    }

    .ep-username {
        right: 10%;
    }

    .setting-icon {
        top: 1%;
        right: 1.4%;
    }

    .ep-container {
        border: none;
       }
    
}

@media only screen and (max-width: 430px) {
    .ep-email {
        left: 4%;
    }

    .ep-category {
        right: 4%;
    }

    .ep-name {
        left: 4%;
    }

    .ep-username {
        right: 4%;
    }

    .ep-container {
        border: none;
       }
    
    
    
}

@media only screen and (max-width: 430px) {

   .ep-container {
    border: none;
   }

    .ep-email {
        max-width: 120px;
    }

    .ep-category {
        max-width: 120px;
    }

    .ep-name {
        max-width: 120px;
    }

    .ep-username {
        max-width: 120px;
    }
    
    
}


/* Ensures no button styles (background, border) are visible */
.heart-button {
    background: none;           /* No background */
    border: none;               /* No border */
    cursor: pointer;           /* Make it clickable */
    padding: 0;                 /* No padding */
    font-size: 30px;            /* Icon size */
    outline: none;              /* No outline */
    display: inline-block;      /* Display icon inline */
}

/* Style for the heart icon */
.heart-button i {
    color: brown;              /* Default color of the heart */
    stroke: currentColor;      /* Stroke color matches icon color */
    stroke-width: 1.5px;       /* Stroke width for the heart */
    transition: color 0.3s ease, stroke 0.3s ease; /* Smooth transition for color and stroke */
}

/* When the heart is liked, change the color */
.heart-button.liked i {
    color: brown;              /* Heart color when liked */
    stroke-width: 0;           /* Remove stroke when filled */
}

/* Prevent any hover effects on the button */
.heart-button:hover {
    background: none;          /* Ensure no background on hover */
    border: none;              /* Ensure no border on hover */
}

.heart-button:hover i {
    color: inherit;            /* Keep the icon color consistent on hover */
    stroke-width: 1.5px;       /* Ensure stroke remains if hovered */
}

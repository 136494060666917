/* photoModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: rgb(15, 15, 15);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    
    position: absolute;
    max-width: 600px;
    width: 70%;
    height: 80vh;
}

.modal-photo {
    width: 350px;
    height: 350px;
    object-fit: cover;
    border-radius: 10px;
    position: absolute;
    top: 15%;
}

.modal-description {
   position: absolute;
   top: 67%;
   font-size: 1rem;
}

.modal-time {
   position: absolute;
   bottom: 1%;
   left: 4%;
   font-size: .7rem;

}

.modal-close {
    position: absolute;
    top: 2%;
    right: 2%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter-Black';
    background:  rgb(15,15,15);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.delete-post {
    position: absolute;
    bottom: 8%;
}

@media only screen and (max-width: 540px) {

    .modal-content {
        width: 80%;
        height: 70vh;
    }

    .modal-photo {
        width: 300px;
        height: 300px;
        top: 20%;
    }

    .delete-post {
        bottom: 10%;
    }
    
}

@media only screen and (max-width: 430px) {

    .modal-content {
        width: 80%;
    }

    .modal-photo {
        width: 260px;
        height: 260px;
        top: 22%;
    }
    
}

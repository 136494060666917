.gallery-container {
    position: absolute;
    top: 100%;
    width: 80%;
    max-width: 600px;
    /* background-color: blue; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    padding-bottom: 100px;
    gap: 50px;
}

.gallery-photos {
    width: 300px;
    height: 300px;
    border-radius: 10px;
    object-fit: cover;
    cursor: pointer;
}


@media only screen and (max-width: 540px) {
    .gallery-container {
        max-width: 350px;
        gap: 35px;
    }

    .gallery-photos {
        width: 175px;
        height: 175px;
    }
    
}

@media only screen and (max-width: 430px) {
    .gallery-container {
        max-width: 300px;
        gap: 35px;
    }

    .gallery-photos {
        width: 150px;
        height: 150px;
    }
    
}
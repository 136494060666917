.register-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    max-width: 500px;
    height: 85vh;
    /* background-color: rgb(28, 27, 27); */
    border: 1px solid rgb(101, 101, 101);
    border-radius: 20px;
    /* top: 35%; */
    position: absolute;
}

.logo-register {
    position: absolute;
    top: 8%;
    width: 70%;
    max-width: 400px;
}

.create-account {
    position: absolute;
    top: 35%;
    color: brown;
}

.register-email {
    position: absolute;
    top: 46%;
}

.register-password {
    position: absolute;
    top: 55%;
}

.checkbox {
    width: 12px;
    border-radius: 8px;
    cursor: pointer;
}

.terms-container {
    width: 70%;
    max-width: 180px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    bottom: 32%;
    cursor: pointer;
}

.register-button {
    position: absolute;
    bottom: 20%;
}

.login-link {
    position: absolute;
    bottom: 5%;
    /* color:chartreuse; */
    font-size: .8rem;
    cursor: pointer;
}

.forgot-password {
    position: absolute;
    bottom: 30%;
    cursor: pointer;
}

.error-message {
    position: absolute;
    top: 25%;
    color: darkorange;
}

.google-button {
    position: absolute;
    bottom: 4%;
}

.privacy {
    position: absolute;
    bottom: 5%;
    font-size: .8rem;
    color: brown;
    cursor: pointer;
}

/* username  */

.username-container {
    width: 70%;
    max-width: 500px;
    height: 67vh;
    border: 1px solid rgb(101, 101, 101);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.logo-username {
    position: absolute;
    top: 10%;
    width: 70%;
    max-width: 300px;
}


.add-username {
    position: absolute;
    /* bottom: 37%; */
    color: brown;
}

.username-input {
    position: absolute;
    bottom: 38%;
}


.create-button {
    position: absolute;
    bottom: 10%;
}

/* TERMS  */
.terms-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(9, 9, 9);
    padding: 30px;
    border-radius: 20px;
    width: 80%;
    max-width: 500px;
    height: 70vh;
    position: absolute;
    overflow-y: auto;
    font-size: 10px;
}

/* forgot password modal  */
.forgot-modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: rgb(11, 11, 11);
    width: 80%;
    max-width: 400px;
    height: 70vh;
    padding: 20px;
}

.fp {
    position: absolute;
    top: 35%;
}

.otp-email {
    position: absolute;
   
}



.submit-otp {
    position: absolute;
    top: 60%;
}

.close-modal-otp {
    position: absolute;
    top: 1%;
    right: 3%;
    background-color: rgb(11, 11, 11);
    border: none;
    width: 20px;
    height: 20px;
}

.reset-message {
    position: absolute;
    top: 0;
}

.em-fp {
    position: absolute;
    top: 2%;
}



@media only screen and (max-width: 540px) {
    .register-email {
        top: 48%;
    }

    .register-button {
        bottom: 20%;
    }


}

@media only screen and (max-width: 390px) 
 {
    .register-container {
        height: 70vh;
    }

    .create-account {
        top: 35%;
    }

    .register-email {
        top: 44%;
    }

    .register-password {
        top: 53%;
    }

    .login-link {
        bottom: 2%;
    }
    
}

@media only screen and (max-width: 430px)  {
    .register-container {
        height: 65vh;
    }

    .create-account {
        top: 35%;
    }

    .register-email {
        top: 44%;
    }

    .register-password {
        top: 53%;
    }

    .login-link {
        bottom: 8%;
    }
    
}


@media only screen and (width: 1366px) and (height: 1024px) {
    .register-container {
        height: 70vh;
    }

    
}

@media only screen and (width: 1024px) and (height: 1366px) {
    .register-container {
        height: 65vh;
    }

    
}

@media only screen and (max-width: 834px) {
    .register-container {
        height: 70vh;
    }

    
}
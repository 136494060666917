.notification-container {
    max-width: 600px;
    width: 70%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid rgb(101, 101, 101);
    border-radius: 20px;
    position: absolute;
    top: 20%;
}

.not-header {
    max-width: 700px;
    width: 80%;
    height: 15vh;
    position: absolute;
    top: 0;
    /* background-color: antiquewhite; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.not {
  color: brown;
  position: absolute;
  top: 10%;
}

.not-followers {
    position: absolute;
    top: 50%;
    left: 20%;
}

.not-following {
    position: absolute;
    top: 50%;
    right: 20%;
}

.not-background {
    position: absolute;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(21, 21, 21);
    border-radius: 10px;
    width: 70%;
    max-width: 500px;
    top: 30%;
    height: 35vh;
    overflow-y: auto;
    padding: 20px;
    
}

.notification {
    width: 80%;
    max-width: 500px;
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    padding: 20px;
    font-size: .8rem;
    
}


@media only screen and (max-width: 540px) {
  .not-followers {
    left: 8%;
  }

  .not-following {
    right: 8%;
  }

  .notification-container {
    height: 65vh;
  }

  .notification-container {
    border: none;
  }
    
}

@media only screen and (max-width: 430px) {
  .not-followers {
    left: 0;
    max-width: 100px;
  }

  .not-following {
    right: 0%;
    max-width: 100px;
  }

  .notification-container {
    height: 65vh;
    /* border: none; */

  }

    
}

  
.upload-container {
    position: absolute;
    top: 9%;
    width: 70%;
    max-width: 550px;
    height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(101, 101, 101);
    border-radius: 20px;
}

.upload-text {
    position: absolute;
    top: 17%;
    color: brown;
}

.upload-photo {
    width: 300px;
    height: 300px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    background-color: rgb(18, 17, 17);
    position: absolute;
    top: 18%;
    cursor: pointer;
}

.description {
    position: absolute;
    top: 70%;
    width: 250px;
    background-color: black;
    border-radius: 10px;
    height: 50px;
    color: aliceblue;
}

.upload-button {
    position: absolute;
    bottom: 7%;
}

.imagepreview-upload {
    width: 300px;
    height: 300px;
    border-radius: 10px;
    object-fit: cover;
    
}

.logo-upload {
    position: absolute;
    top: 2%;
    
    max-width: 250px;
    width: 50%;
}

@media screen and (max-width: 540px) {
    .upload-container {
        border: none;
    }

    
}

@media only screen and (max-width: 430px) {
    .upload-photo {
        width: 220px;
        height: 220px;
        top: 24%;
    }

    .imagepreview-upload {
        width: 220px;
        height: 220px;
    }

    .description {
        width: 220px;
    }

    .upload-text {
        top: 22%;
    }

    .logo-upload {
        width: 65%;
        top: 6%;
    }

    .upload-container {
        border: none;
    }
    
}

@media only screen and (max-width: 390px) {
    .upload-photo {
        width: 200px;
        height: 200px;
        top: 25%;
    }

    .imagepreview-upload {
        width: 200px;
        height: 200px;
    }

    .upload-text {
        top: 23%;
    }

    .upload-container {
        border: none;
    }
    
}


/* @media only screen and (max-width: 1280px) {
    .upload-photo {
        width: 200px;
        height: 200px;
    }

    .imagepreview-upload {
        width: 200px;
        height: 200px;
    }

    .upload-text {
        font-size: 1.1rem;
    }
    
}

@media only screen and (max-width: 834px) {
    .upload-container {
        height: 70vh;
        top: 10%;
    }
    .upload-photo {
        width: 250px;
        height: 250px;
        top: 30%;
    }

    .imagepreview-upload {
        width: 250px;
        height: 250px;
    }

    .upload-button {
        max-width: 170px;
        height: 48px;
        font-size: 1.1rem;
    }

    .upload-text {
        font-size: 1.3rem;
        top: 20%;
    }

    .logo-upload {
        top: 5%;
    }

    .description {
        height: 70px;
    }
    
}




 */

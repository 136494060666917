body {
    background-color: black;
    color: aliceblue;
    margin: 0;
    padding: 0;
}

.layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

p {
    font-family: 'Inter-SemiBold';
}

h1 {
    font-family: 'Inter-Black';
}

input {
    width: 70%;
    max-width: 200px;
    height: 40px;
    border: none;
    border-radius: 8px;
    background-color: rgb(236, 233, 233);
    font-family: 'Inter-SemiBold';
    color: rgb(37, 38, 38);
}

select {
    width: 70%;
    max-width: 200px;
    height: 40px;
    border: 3px solid brown;
    border-radius: 8px;
    background-color: rgb(236, 233, 233);
    color: rgb(37, 38, 38);
    font-family: 'Inter-SemiBold';
}

input::placeholder {
    color: rgb(49, 50, 51);
    font-family: 'Inter-SemiBold';
}

button {
    width: 70%;
    max-width: 120px;
    height: 40px;
    border-radius: 10px;
    background-color: rgb(30, 29, 29);
    color: aliceblue;
    cursor: pointer;
}

button:hover {
    background-color: brown;
}


/* fonts  */

@font-face {
    font-family: 'Inter-SemiBold';
    src: url('./assets/fonts/Inter-SemiBold.ttf');
}

@font-face {
    font-family: 'Inter-Black';
    src: url('./assets/fonts/Inter-Black.ttf');
}

@media (width: 1366px) {
    input {
       
        font-size: 17px;
    }

    input::placeholder {
        font-size: 17px;
    }

    p {
        font-size: 26px;
    }

    button {
        max-width: 250px;
        height: 60px;
        font-size: 24px;
    }

    select {
        max-width: 420px;
        font-size: 17px;
    }

    
}
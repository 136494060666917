.home-container {
    position: absolute;
    width: 80%;
    max-width: 750px;
    top: 30%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: center;
    
    gap: 50px;
    padding-bottom: 100px;
}

.home-photo {
    width: 250px;
    height: 250px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 10px;
}

.logo-home {
    top: 2%;
    position: absolute;
    max-width: 400px;
    width: 50%;
}

@media only screen and (max-width: 540px) {
    .home-container {
        grid-template-columns: repeat(2, 1fr);
        max-width: 400px;
        gap: 30px;
        top: 25%;
    }

    .home-photo {
        width: 200px;
        height: 200px;
    }

    .logo-home {
        top: 7%;
    }
    
}

@media only screen and (max-width: 430px) {
    .home-container {
       
        max-width: 350px;
        gap: 25px;
        top: 22%;
    }

    .home-photo {
        width: 175px;
        height: 175px;
    }

    .logo-home {
        top: 5%;
        width: 58%;
    }
    
}

@media only screen and (max-width: 390px) {
    .home-container {
       
        max-width: 300px;
        gap: 25px;
        top: 22%;
    }

    .home-photo {
        width: 150px;
        height: 150px;
    }

    .logo-home {
        top: 5%;
        width: 58%;
    }
    
}

